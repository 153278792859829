var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SearchTable", {
        ref: "table",
        attrs: {
          name: "group",
          title: "Группы",
          columns: _vm.columns,
          dynamic: "",
          apiTopic: "client",
          apiPath: "/group2?paged=1",
          metaApiTopic: "client",
          metaApiPath: "/meta/group/search",
          toolbar: _vm.toolbar,
          state: _vm.state.params
        },
        on: { "toolbar-click": _vm.toolbarClick },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function({ rec }) {
              return [
                _c("a", { attrs: { href: "/group/" + rec.entity } }, [
                  _vm._v(_vm._s(rec.name))
                ])
              ]
            }
          },
          {
            key: "responsible",
            fn: function({ rec }) {
              return [
                _vm._v(
                  "\n    " + _vm._s(_vm.workerName(rec.responsible)) + "\n  "
                )
              ]
            }
          },
          {
            key: "type_building",
            fn: function({ rec }) {
              return [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.typeBuildingName(rec.type_building)) +
                    "\n  "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }