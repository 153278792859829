var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row", staticStyle: { "padding-top": "1em" } },
    [
      _c("div", { staticClass: "col-md-3 text-center" }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.model.name))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-group-vertical",
            attrs: { role: "group", "aria-label": "..." }
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-default",
                attrs: { href: "/history/group/" + this.state.id }
              },
              [_vm._v("История изменений")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-default text-danger",
                on: { click: _vm.delGroup }
              },
              [
                _c("span", { staticClass: "glyphicon glyphicon-trash" }),
                _vm._v(" Удалить\n          ")
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-9" }, [
        _c("form", { staticClass: "form-horizontal" }, [
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c("Number", {
                attrs: {
                  label: "Номер",
                  size: 7,
                  name: "gid",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.gid,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "gid", $$v)
                  },
                  expression: "model.gid"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Название",
                  size: 7,
                  name: "name",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Описание",
                  size: 7,
                  name: "descr",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.descr,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "descr", $$v)
                  },
                  expression: "model.descr"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Ответственный бригадир",
                  size: 7,
                  name: "responsible",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  options: _vm.activeWorkers
                },
                model: {
                  value: _vm.model.responsible,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "responsible", $$v)
                  },
                  expression: "model.responsible"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Владелец",
                  size: 7,
                  name: "owner",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  options: _vm.$store.state.preload["/fop"],
                  valueName: "entity",
                  textName: "display_name"
                },
                model: {
                  value: _vm.model.owner,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "owner", $$v)
                  },
                  expression: "model.owner"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Тип застройки",
                  size: 7,
                  name: "type_building",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  options: _vm.typeBuilding
                },
                model: {
                  value: _vm.model.type_building,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "type_building", $$v)
                  },
                  expression: "model.type_building"
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", {
        staticClass: "glyphicon glyphicon-th-list",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("  "),
      _c("a", { attrs: { href: "/group" } }, [_vm._v("Группы пользователей")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }