var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-9" }, [
      _c("form", { staticClass: "form-horizontal" }, [
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c("Number", {
              attrs: { label: "Номер группы", size: 7, error: _vm.err.gid },
              on: {
                input: function($event) {
                  _vm.err.gid = null
                }
              },
              model: {
                value: _vm.model.gid,
                callback: function($$v) {
                  _vm.$set(_vm.model, "gid", $$v)
                },
                expression: "model.gid"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: { label: "Название", size: 7, error: _vm.err.name },
              on: {
                input: function($event) {
                  _vm.err.name = null
                }
              },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: { label: "Описание", size: 7, error: _vm.err.descr },
              on: {
                input: function($event) {
                  _vm.err.descr = null
                }
              },
              model: {
                value: _vm.model.descr,
                callback: function($$v) {
                  _vm.$set(_vm.model, "descr", $$v)
                },
                expression: "model.descr"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Ответственный бригадир",
                size: 7,
                error: _vm.err.responsible,
                options: _vm.activeWorkers
              },
              on: {
                input: function($event) {
                  _vm.err.responsible = null
                }
              },
              model: {
                value: _vm.model.responsible,
                callback: function($$v) {
                  _vm.$set(_vm.model, "responsible", $$v)
                },
                expression: "model.responsible"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Владелец",
                size: 7,
                error: _vm.err.owner,
                options: _vm.$store.state.preload["/fop"],
                valueName: "entity",
                textName: "display_name"
              },
              on: {
                input: function($event) {
                  _vm.err.owner = null
                }
              },
              model: {
                value: _vm.model.owner,
                callback: function($$v) {
                  _vm.$set(_vm.model, "owner", $$v)
                },
                expression: "model.owner"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Тип застройки",
                size: 7,
                error: _vm.err.type_building,
                options: _vm.typeBuilding
              },
              on: {
                input: function($event) {
                  _vm.err.type_building = null
                }
              },
              model: {
                value: _vm.model.type_building,
                callback: function($$v) {
                  _vm.$set(_vm.model, "type_building", $$v)
                },
                expression: "model.type_building"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn btn-primary", on: { click: _vm.submit } },
          [_c("Icon", { attrs: { name: "save" } }), _vm._v(" Сохранить")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-3 text-center" }, [
      _c("span", { staticClass: "lead" }, [
        _vm._v("Новая группа пользователей")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }