<template>

  <div>
    <SearchTable ref=table name="group" title="Группы" :columns=columns dynamic 
     apiTopic=client apiPath="/group2?paged=1" 
     metaApiTopic="client" metaApiPath="/meta/group/search"
     :toolbar=toolbar :state=state.params
     @toolbar-click=toolbarClick
    >
      
    <template #name="{rec}">
      <a :href="'/group/' + rec.entity">{{ rec.name }}</a>
    </template>

    <template #responsible="{rec}">
      {{ workerName(rec.responsible) }}
    </template>

    <template #type_building="{rec}">
      {{ typeBuildingName(rec.type_building) }}
    </template>

    </SearchTable>

  </div>
</template>
  
<script>
  import Vue from "vue/dist/vue.esm.js"

  import darsan from "darsan"
  import { eventBus } from "main"
  import { goto } from "navigation"

  export default {
    name: "GroupTable",
    
    components: { },
  
    props: {
      state: Object,
    },
    async created () {
	    await this.ensure("worker");
    	await this.ensure("group-type-building");
    },
    data () {
      return {
        
        toolbar: [
          {
            name: "add",
            icon: "plus",
            text: "Добавить",
          },
        ],
        
        columns: [
          {
            name: "gid",
            title: "ID",
            sort: true,
          },
          {
            name: "name",
            title: "Название",
            sort: true,
          },
          {
            name: "descr",
            title: "Описание",
            sort: true,
          },
          {
            name: "responsible",
            title: "Ответственный",
            sort: true,
          },
          {
            name: "type_building",
            title: "Тип строения",
            sort: true,
          },
        ],
        
      }
    },
    
    activated () {
      if (this.$refs.table) this.$refs.table.$emit("reloadTable")
    },
    
    methods: {
      toolbarClick (msg) {
        if (msg.from == "add") {
          goto("/group/!new")
        }
      },
      
      workerName(entity)
      {
        if (!entity) return ""
        const rec = this.$store.state.preload["/worker"].find(el => el.entity==entity)
        if (rec) return `${rec.surname} ${rec.name}`
        return "?"
      },

      typeBuildingName(entity)
      {
        if (!entity) return ""
        const rec = this.$store.state.preload['/group-type-building'].find(el => el.value==entity)
        if (rec) return `${rec.text}`
        return "?"
      },
    },
  }
  
</script>
