<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>

import StdPage from "common/visual/StdPage.vue"
import GroupNew    from "group/page/GroupNew.vue"
import GroupTable  from "group/page/GroupTable.vue"
import GroupEditor from "group/page/GroupEditor.vue"

import { eventBus } from "main"

export default {
  name: "GroupPage",
  title: "Группы пользователей",
  mixins: [StdPage],
  
  data () {
    return {
      overlays: [
        {
          re: /\/group\/\d+/,
          component: GroupEditor,
        },
        {
          re: /\/group\/!new/,
          component: GroupNew,
        },
        {
          re: /(?:)/,
          component: GroupTable,
        },
      ],
    }
  },
  
}

</script>
